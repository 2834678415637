import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import {
  GetInTouch,
  Layout,
  Paragraph,
  SEO,
  SocialMedia,
  ValuesGrid,
} from "../components"
import { Colors } from "../utils/constants"

const parsingValues = values => {
  return values.map(value =>
    value.icon ? { ...value, icon: value.icon.file.url } : value
  )
}

const About = ({ data }) => {
  const introPost = data.introPost.nodes[0]
  const closingPost = data.closingPost.nodes[0]
  const socialMedias = data.socialMedias.nodes[0]
  const companyValue = data.companyValue.nodes[0]
  const teamValue = data.teamValue.nodes[0]
  const customerValue = data.customerValue.nodes[0]
  const getInTouch = data.getInTouch.nodes[0]

  const meetingCards = getInTouch.meetingCards.map(
    ({ content, icon, link, title }) => ({
      icon: icon.file.url,
      title: title,
      titleColor: Colors.RED_2,
      description: content.content,
      link: { label: link.title, href: link.url, theme: "red" },
      backgroundColor: Colors.PINK,
    })
  )
  const locations = getInTouch.location.map(
    ({ addressLine1, addressLine2, country, phone, postalCode }) => ({
      country: country,
      address1: addressLine1,
      address2: addressLine2,
      zip: postalCode,
      phone: phone,
    })
  )

  return (
    <Layout>
      <SEO title="Who we are" />
      {/* landing section */}
      <StyledIntroPost>
        <Row>
          <Col col="12" md="8">
            <Paragraph
              title={introPost.title}
              content={introPost.content.content}
            />
          </Col>
        </Row>
      </StyledIntroPost>
      <StyledValuesContainer>
        {/* company values section */}
        <ValuesGrid
          isReversed
          title={companyValue.title}
          mediaObjects={parsingValues(companyValue.mediaObject)}
          link={{
            label: companyValue.link.content,
            href: companyValue.link.url,
            theme: "red",
          }}
          fixedSources={companyValue.thumbnail?.localFile?.childImageSharp}
        />
        {/* team values section */}
        <ValuesGrid
          title={teamValue.title}
          mediaObjects={parsingValues(teamValue.mediaObject)}
          link={{
            label: teamValue.link.content,
            href: teamValue.link.url,
            theme: "red",
          }}
          fixedSources={teamValue.thumbnail?.localFile?.childImageSharp}
        />
        {/* customer values section */}
        <ValuesGrid
          isReversed
          title={customerValue.title}
          mediaObjects={parsingValues(customerValue.mediaObject)}
          link={{
            label: customerValue.link.content,
            href: customerValue.link.url,
            theme: "red",
          }}
          fixedSources={customerValue.thumbnail?.localFile?.childImageSharp}
        />
      </StyledValuesContainer>
      {/* push your career section */}
      <StyledClosingPost>
        <Paragraph
          title={closingPost.title}
          content={closingPost.content.content}
          link={{
            href: closingPost.link.url,
            label: closingPost.link.content,
          }}
          fixedSources={closingPost.thumbnail.localFile.childImageSharp}
        />
      </StyledClosingPost>
      {/* Get In Touch section */}
      <GetInTouch
        title={getInTouch.title}
        content={getInTouch.content.content}
        meetingCards={meetingCards}
        locations={locations}
      />
      {/* social media section */}
      <SocialMedia
        socialMedias={{
          socialMedia: socialMedias.socialMedia,
        }}
      />
    </Layout>
  )
}

//TODO: use just one container
const StyledIntroPost = styled(Container)`
  margin-top: 90px;
  padding: 30px;
  ${media.md`
    height: auto;
    margin-top: 170px;
    margin-bottom: 200px;
  `}
`
const StyledClosingPost = styled(Container)`
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 30px;
  ${media.md`
    height: auto;
    margin-top: 170px;
    margin-bottom: 200px;
  `}
`

const StyledValuesContainer = styled(Container)`
  & > div:not(:last-of-type) {
    margin-bottom: 92px;
  }
  ${media.md`
    & > div:not(:last-of-type) {
      margin-bottom: 230px;
    }
  `}
`

export default About

export const query = graphql`
  query aboutQuery(
    $page: ContentfulPageFilterListInput = {
      elemMatch: { title: { eq: "About" } }
    }
  ) {
    introPost: allContentfulPost(filter: { page: $page, order: { eq: 0 } }) {
      nodes {
        ...post
      }
    }
    closingPost: allContentfulPost(filter: { page: $page, order: { eq: 1 } }) {
      nodes {
        ...post
        thumbnail {
          localFile {
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              mobileImage: fixed(toFormat: WEBP, width: 300, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              tabletImage: fixed(toFormat: WEBP, width: 400, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 900, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    companyValue: allContentfulMediaObjectGrid(
      filter: { page: $page, order: { eq: 0 } }
    ) {
      nodes {
        ...mediaObjectGrid
        thumbnail {
          localFile {
            childImageSharp {
              mobileImage: fixed(toFormat: WEBP, width: 375, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              tabletImage: fixed(toFormat: WEBP, width: 400, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 550, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    teamValue: allContentfulMediaObjectGrid(
      filter: { page: $page, order: { eq: 1 } }
    ) {
      nodes {
        ...mediaObjectGrid
        thumbnail {
          localFile {
            childImageSharp {
              mobileImage: fixed(toFormat: WEBP, width: 350, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              tabletImage: fixed(toFormat: WEBP, width: 360, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 375, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    customerValue: allContentfulMediaObjectGrid(
      filter: { page: $page, order: { eq: 2 } }
    ) {
      nodes {
        ...mediaObjectGrid
        thumbnail {
          localFile {
            childImageSharp {
              mobileImage: fixed(toFormat: WEBP, width: 375, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              tabletImage: fixed(toFormat: WEBP, width: 400, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 550, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    getInTouch: allContentfulMeetingBlock(filter: { page: $page }, limit: 1) {
      nodes {
        ...meetingBlock
      }
    }
    socialMedias: allContentfulSocialMediaGroup {
      nodes {
        ...socialMedia
      }
    }
  }
`
